import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CirculerLoader = () => {
    return ( 
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">   
            <CircularProgress color='primary' style={{width : '50px',height: '50px'}}/>
        </Box>
    );
}
 
export default CirculerLoader;