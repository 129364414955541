import { Box, Stack, Typography } from "@mui/material";
import Logoimg from "../Assets/icons/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <Box sx={container}>
             <Stack 
                spacing={{xs: 1,sm: 2}}
                alignItems="center"
            >
                
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box sx={{width: {xs: '40px', lg: '60px', xl: '70px'}}}>
                        <img src={Logoimg} alt="logo" style={{width: '100%', objectFit: 'cover'}} loading="lazy"/>
                    </Box>
                    <Typography variant="Headings" fontSize={{xs: '20px',md:'25px', xl: '50px'}}>R.S Technologies</Typography>
                </Stack>
                <Typography fontWeight="600">Building Solution, Powering Futures</Typography>
                
            </Stack>
            <Stack marginTop={{xs: '20px',sm: '0px'}} direction={{xs: 'column',md: 'row'}} spacing={{xs: 2,md: 10}}>
                <Stack spacing={1}>
                    <Typography fontSize="20px" fontWeight={600}>Contact us</Typography>
                    <Typography fontWeight={200}>+91 7219047556</Typography>
                    <Lightfont>ukb.rstechnology@gmail.com</Lightfont>
                    <Lightfont>Shed No-D/73, Maruti Industrial Estate, Nr-Ring Road, Hathijan, Ahmedabad
                    </Lightfont>
                </Stack>
                
                <Stack spacing={1}>
                    <Typography fontSize="20px" fontWeight={600}>Pages</Typography>
                    <Link to="/" smooth={true} ><Lightfont cursor='pointer'>Home</Lightfont></Link>
                    <Link to="About" smooth={true}><Lightfont cursor='pointer'>About</Lightfont></Link>
                    <Link to="services" smooth={true}><Lightfont cursor='pointer'>Services</Lightfont></Link>
                    <Link to="projects" smooth={true}><Lightfont cursor='pointer'>Projects</Lightfont></Link>
                    <Link to="News" smooth={true}><Lightfont cursor='pointer'>News</Lightfont></Link>
                    <Link to="Contactus" smooth={true}><Lightfont cursor='pointer'>Contact us</Lightfont></Link>
                </Stack>
            </Stack>
        </Box>    
    );
}

const Lightfont = ({children,cursor}) => {
    return(
        <Typography sx={{cursor: cursor && "pointer",color: '#fff', textTransform: 'none'}} fontWeight={200}>{children}</Typography>
    )
}

const container = {
    backgroundColor: '#144557',
    color: '#fff',
    mt: 5,
    p: 5,
    display: 'flex',
    minHeight: '40vh',
    flexDirection: {xs: 'column', md: 'row'},
    alignItems: 'center',
    justifyContent: 'space-around',
}
 
export default Footer;