import React, { lazy } from "react";
import { createBrowserRouter, } from "react-router-dom";
import RootLayout from "../layout/Rootlayout";
import ErrorPage from "../components/ErrorPage";
import axios from "axios";
import importAllImages from "../utils/imagesImporter";

const Home = lazy(() => import("../pages/Home/Home"));
const About = lazy(() => import("../pages/About/About"));
const Services = lazy(() => import("../pages/services/service"));
const Work = lazy(() => import("../pages/work/work"));
const Contactus = lazy(() => import("../pages/contact/contact"));
const News = lazy(() => import("../pages/News/news"));

const NEWSAPILINK = "https://newsapi.org/v2/everything?q=Mechanical%20Engineering%20and%20Technology%20News&sortBy=popularity&apiKey=32420470b8554916a1409dd5a9441c84&page=1&pageSize=30"

export const AppRouter = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                index: true,
                element: <Home />,
            },
            {
                path: 'about',
                element: <About />,
            },
            {
                path: 'services',
                element: <Services />,
            },
            {
                path: 'projects',
                element: <Work />,
                loader: (async() => {
                    const images = importAllImages(require.context('../Assets/work', false, /\.(png|jpe?g|svg)$/));
                    return images;
                })
            },
            {
                path: 'contactus',
                element: <Contactus />
            },
            {
                path: 'news',
                element: <News />,
                loader: (async ({ request }) => {
                            const res = await axios.get(NEWSAPILINK);
                            const newsdata = await res?.data?.articles;
                            return newsdata;
                        })
            },
           
        ]
    },
]);