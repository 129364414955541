import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Suspense } from "react";
import CirculerLoader from "../components/Loader";

const RootLayout = () => {
    return ( 
        <div>
           <Navbar />
           <Suspense fallback={<CirculerLoader />}>
               <Outlet />
            </Suspense>
           <Footer />
        </div>
     );
}
 
export default RootLayout;